<template>
    <div class="relation_box">
        <div class="submit_form">
            <div>
                <p>
                    <span>客户名称</span><span>{{ order.companyName }}</span>
                </p>
                <p>
                    <span>销售订单编号</span><span>{{ order.orderNo }}</span>
                </p>
            </div>
            <div>
                <p>
                    <span style="width: 95px">所属部门</span
                    ><span
                        ><el-tooltip placement="top"
                            >·
                            <div slot="content">
                                <p
                                    style="line-height: 2"
                                    v-for="item in order.departmentAdminDOList"
                                    :key="item.id"
                                >
                                    {{ item.departmentName }}
                                </p>
                            </div>
                            <em style="font-style: normal">
                                {{ returnName(order) }}
                            </em>
                        </el-tooltip></span
                    >
                </p>
                <p>
                    <span>所属人</span><span>{{ order.adminName }}</span>
                </p>
            </div>
        </div>
        <div class="table_box" v-if="order.productCategory != 12">
            <el-table
                :data="tableData"
                height="100%"
                :header-cell-style="{
                    background: '#FAFAFA',
                    color: '#333333',
                    'font-size': '14px',
                    'font-weight': '600',
                }"
            >
                <el-table-column prop="updateTime" label="交付时间">
                    <template slot-scope="scope">
                        <div
                            :style="{
                                color:
                                    scope.row.contractStatus == 3 ? 'red' : '',
                            }"
                        >
                            {{
                                scope.row.placeOrderTime
                                    ? scope.row.placeOrderTime.split(' ')[0]
                                    : '- -'
                            }}
                        </div>
                    </template>
                </el-table-column>
                <el-table-column
                    prop="updateTime"
                    label="产品名称"
                    show-overflow-tooltip
                >
                    <template slot-scope="scope">
                        <div
                            :style="{
                                color:
                                    scope.row.contractStatus == 3 ? 'red' : '',
                            }"
                        >
                            {{
                                $tableDataHandle.productCategory(
                                    scope.row.productCategory
                                )
                            }}
                        </div>
                    </template>
                </el-table-column>
                <el-table-column label="类型" show-overflow-tooltip>
                    <template slot-scope="scope">
                        <div
                            :style="{
                                color:
                                    scope.row.contractStatus == 3 ? 'red' : '',
                            }"
                        >
                            <span>{{
                                scope.row.productCategory == 1
                                    ? $MailStatus.planType(scope.row.planType)
                                    : $MailStatus.planTypeAli(
                                          scope.row.planType
                                      )
                            }}</span>
                        </div>
                    </template>
                </el-table-column>
                <el-table-column
                    prop="updateTime"
                    label="账号数"
                    show-overflow-tooltip
                >
                    <template slot-scope="scope">
                        <div
                            :style="{
                                color:
                                    scope.row.contractStatus == 3 ? 'red' : '',
                            }"
                        >
                            <span
                                v-if="
                                    scope.row.productCategory == 1 ||
                                    scope.row.productCategory == 2 ||
                                    scope.row.productCategory == 4 ||
                                    scope.row.productCategory == 10
                                "
                            >
                                {{ scope.row.number || '0' }} 用户
                            </span>
                            <span v-else> - - </span>
                        </div>
                    </template>
                </el-table-column>
                <el-table-column
                    prop="updateTime"
                    label="年限"
                    show-overflow-tooltip
                >
                    <template slot-scope="scope">
                        <div
                            :style="{
                                color:
                                    scope.row.contractStatus == 3 ? 'red' : '',
                            }"
                        >
                            <!-- <span
                                v-if="
                                    scope.row.productCategory == 1 ||
                                    scope.row.productCategory == 2 ||
                                    scope.row.productCategory == 4 ||
                                    scope.row.productCategory == 3
                                "
                            >
                                {{ (scope.row.term / 365).toFixed(2) || 0 }} 年
                            </span> -->
                            <span>
                                {{ returnTrem(scope.row) }} 年

                            </span>
                        </div>
                    </template>
                </el-table-column>
                <el-table-column prop="updateTime" label="交付订单编号">
                    <template slot-scope="scope">
                        <!-- <div
                            :style="{
                                color:
                                    scope.row.contractStatus == 3 ? 'red' : '',
                            }"
                        >
                            {{
                                scope.row.payTime
                                    ? scope.row.payTime.split(' ')[0]
                                    : '- -'
                            }}
                        </div> -->

                        <span>
                                {{ scope.row.orderPlanNo }}

                            </span>
                    </template>
                </el-table-column>
                <div slot="empty" class="empty">
                    <img src="@/assets/zanwushuju.png" />
                    <span style="display: block">暂无数据～</span>
                </div>
            </el-table>
        </div>
        <div class="table_box" v-else>
            <el-table
                ref="multipleTable"
                :data="tableData"
                height="100%"
                :header-cell-style="{
                    background: '#FAFAFA',
                    color: '#333333',
                    'font-size': '14px',
                    'font-weight': '600',
                }"
            >
                <el-table-column
                    label="客户名称"
                    show-overflow-tooltip
                    width="192"
                >
                    <template slot-scope="scope">
                        <span>{{ scope.row.customerName || '- -' }}</span>
                    </template>
                </el-table-column>
                <el-table-column label="域名" show-overflow-tooltip width="182">
                    <template slot-scope="scope">
                        <span>{{ scope.row.domain || '- -' }}</span>
                    </template>
                </el-table-column>

                <el-table-column label="类型" show-overflow-tooltip>
                    <template slot-scope="scope">
                        <span>{{
                            scope.row.planType == 1
                                ? '新购'
                                : scope.row.planType == 2
                                ? '续费'
                                : '- -'
                        }}</span>
                    </template>
                </el-table-column>

                <el-table-column label="年限" show-overflow-tooltip>
                    <template slot-scope="scope">
                        <span>{{
                            scope.row.term ? scope.row.term + '年' : '- -'
                        }}</span>
                    </template>
                </el-table-column>

                <el-table-column label="注册时间" show-overflow-tooltip>
                    <template slot-scope="scope">
                        <span>{{
                            scope.row.openTime
                                ? scope.row.openTime.slice(0, 10)
                                : '- -' || '- -'
                        }}</span>
                    </template>
                </el-table-column>
                <el-table-column
                    label="到期时间"
                    show-overflow-tooltip
                    width="100"
                >
                    <template slot-scope="scope">
                        <span>{{
                            scope.row.expireTime
                                ? scope.row.expireTime.slice(0, 10)
                                : '- -'
                        }}</span>
                    </template>
                </el-table-column>

                <div slot="empty" class="empty">
                    <img src="@/assets/zanwushuju.png" />
                    <span style="display: block">暂无数据～</span>
                </div>
            </el-table>
        </div>
        <div class="footer_btn">
            <el-button type="primary" size="mini" @click="onClose" round
                >关 闭</el-button
            >
        </div>
    </div>
</template>

<script>
import { orderPlanList } from '@/api/order/order.js';
export default {
    data() {
        return {
            tableData: [],
            order: {},
        };
    },
    methods: {
        returnTrem(row){
            if(row.productCategory == 1){
                if(row.planType == 1 || row.planType == 2){
                    return (row.term / 365).toFixed(2);
                }else{
                    return row.term;
                }
            }
            return row.term;
        },
        getData(row) {
            this.order = row;
            console.log(row);
            let data = {
                param: {
                    orderId: this.order.id,
                },
                pageNum: 0,
                pageSize: 0,
            };
            orderPlanList(data).then((res) => {
                console.log(res);
                this.tableData = res.data.list;
            });
        },
        onClose() {
            this.$emit('close');
        },
        num(item) {
            // console.log(item);
            if (
                item.productCategory == 1 ||
                item.productCategory == 2 ||
                item.productCategory == 3 ||
                item.productCategory == 4
            ) {
                var num = 0;
                item.orderPlanBOList.forEach((itm) => {
                    num += Number(itm.term);
                });
                return num + Number(this.form.term) - item.orderTerm > 0
                    ? false
                    : true;
            } else {
                if (item.orderPlanBOList.length > 0) {
                    return false;
                }
            }
        },
        returnName(data) {
            var str = '';
            data.departmentAdminDOList
                ? data.departmentAdminDOList.forEach((item) => {
                      if (item.main == 1) {
                          str = item.departmentName;
                      }
                  })
                : '';
            return str;
        },
    },
};
</script>

<style lang="scss" scoped>
.relation_box {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    background-color: #f0f0f0;
    .footer_btn {
        width: 100%;
        height: 50px;
        line-height: 50px;
        text-align: right;
        border-top: 1px solid #e5e5e5;
        background-color: #fff;
        .el-button--primary {
            background-color: #2370eb;
            border-color: #2370eb;
        }
        button:last-child {
            margin-right: 30px;
        }
    }
    .submit_form {
        flex: 1;
        background-color: #fff;
        padding-top: 16px;
        display: flex;
        div {
            flex: 1;
            margin-left: 34px;
            p {
                padding-bottom: 16px;
            }
            span:nth-child(1) {
                display: inline-block;
                width: 100px;
                color: #666666;
            }
            span:nth-child(2) {
                color: #333333;
            }
        }
    }
}
.table_box {
    height: 300px;
}
.el-form-item--small.el-form-item {
    margin-bottom: 15px !important;
}
</style>
